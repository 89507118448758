.model_button {
    margin-top: 150px;
}

.dashboard_model_button button {
    background: #DD0E0E;
    font-weight: 600;
    color: #fff;
    width: 150px;
    height: 50px;
    border-radius: 25px;
}

/* Add your styles for the modal and other components here */
.dashboard_faculty_modal {
    display: none;
    position: fixed;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 15;
}

.dashboard_faculty_modal_content .dashboard_faculty_modal_content {
    background: #fff !important;
    padding: 0px 5px 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 75vw;
}

.dashboard_faculty_modal_header {
    text-align: right;
}

.dashboard_faculty_modal_close {
    font-size: 30px;
    cursor: pointer;
    padding-right: 12px;
}

.show {
    display: flex;
}

.dashboard_master_faculty_content {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 60px;
    padding: 0px 80px 15px;
    min-width: 77vw;
    min-height: 51vh;
}

.dashboard_faculty_image_left {
    width: 50%;
    text-align: center;
    background-color: #F5F5F7;
    border-radius: 10px;
}

.dashboard_faculty_description_right {
    width: 50%;
}

.dashboard_faculty_master_title h2 {
    color: #222;
    font-weight: 700;
    font-size: 18px;
}

.dashboard_faculty_master_title .dashboard_faculty_degree {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    max-width: 500px;
    color: #222;
    padding: 10px 0;
    overflow: hidden;
}

.dashboard_faculty_master_title .dashboard_faculty_medal {
    font-size: 18px;
    font-weight: 700;
    color: #222;
    padding-bottom: 30px;
}

.dashboard_faculty_master_title p {
    font-size: 16px;
    line-height: 23px;
    color: #707070;
    padding-bottom: 8px;
    max-height: 12rem;
    overflow: scroll;
    text-align: justify;
    font-weight: 400;
}




/* ============================Responsive Tablet========================*/
@media (min-width: 320px) and (max-width: 767px) {
    .dashboard_master_faculty_content {
        flex-direction: column;
    }

    .dashboard_faculty_image_left {
        width: 100%;
    }

    .dashboard_faculty_description_right {
        width: 100%;
    }

    .dashboard_master_faculty_content {
        flex-direction: column;
        gap: 20px;
        max-width: 80vw;
        padding: 0 40px 10px;
    }

    .dashboard_faculty_modal_content {
        max-width: 100vw;
        max-height: 85vh;
        overflow: scroll;
    }
}